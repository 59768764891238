<template>
  <div>
    <vx-card class="pt-5 pb-5">
      <stock-form :new-stock="newStock" @getFileAvatar="getFileAvatar" @getInputValues="editStock" />
    </vx-card>
  </div>
</template>

<script>
import StockForm from '@/views/apps/stock/stock-edit/StockForm.vue'
// Store Module
import moduleStockMixin from '@/store/stock/moduleStockMixin'

export default {
  components: {
    StockForm
  },
  mixins: [moduleStockMixin],
  data () {
    return {
      newStock: { },
      isModified: false,
      fileAvatar: null
    }
  },
  mounted () {
    this.fetchStock()
  },
  methods: {
    getFileAvatar (data) {
      this.fileAvatar = data
    },
    async editStock () {
      this.isModified = true
      if (this.fileAvatar) {
        const responseMediaObject = await this.$store.dispatch('mediaObject/addMediaObjectAvatar', {
          file: this.fileAvatar,
          onUploadProgress: this.onAvatarUpload
        })
        this.newStock.image = responseMediaObject.data['@id']
      }
      this.checkNewstockData()
      
      this.$store.dispatch('stock/editStock', this.newStock)
        .then(() => {
          this.isModified = false
          this.$vs.notify({
            color: 'success',
            title: this.$t('stock.form.edit.success.notify.title', { type: this.newStock.type }),
            text: this.$t('stock.form.edit.success.notify.text', { type: this.newStock.type })
          })
          this.$router.push({name: `stock-list-${this.newStock.type === 'materiel' ? 'materiel' : 'gadget'}`, params: {type: this.newStock.type} })
        })
        .catch((error) => {
          console.error(error)// eslint-disable-line no-console
          this.$vs.notify({
            color: 'danger',
            title: this.$t('stock.form.submit.error.notify.title', { type: this.newStock.type }),
            text: this.$t('stock.form.submit.error.notify.text', { error: error.response.data['hydra:description'] }),
            fixed: true
          })
        })
      
    },
    checkNewstockData () {
      this.$delete(this.newStock, 'stockEvents')
      if (this.newStock.stock) {
        this.newStock.stock = parseInt(this.newStock.stock)
      }
      if (this.newStock.unitPrice) {
        this.newStock.unitPrice = parseFloat(this.newStock.unitPrice)
      }
      if (this.newStock.type === 'brochure') {
        this.$delete(this.newStock, 'company')
        this.$delete(this.newStock, 'unitPrice')
      }
    },
    fetchStock () {
      this.newStock.id = this.$route.params.id
      this.$store.dispatch('stock/fetchStock', this.newStock)
        .then(response => {
          this.newStock = {...response.data}
          if (response.data.imageStock !== null) {
            this.newStock.fileAvatar = response.data.imageStock
          }
          
        })
    }
  }
}
</script>

<style scoped>

</style>
